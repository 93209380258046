import React, { useState } from 'react';
import styles from 'styles/components/section/category/collections-options.module.scss';
import CollectionsList from './CollectionsList'; 
const CollectionsOptions = () => {
    const [layout, setLayout] = useState('vertical'); 

    const handleLayoutChange = (newLayout) => {
        setLayout(newLayout);
    };

    return (
        <div className={styles.collectionsoptions}>
            <div className={styles.grid}>
                <div>
                    <div className={styles.subtitle}>Colecciones:</div>
                    <nav>
                        <a href="#" className={styles.active}>Todas</a>
                        <a href="#">Pride</a>
                        <a href="#">Abstract</a>
                        <a href="#">Animals</a>
                        <a href="#">Love</a>
                        <a href="#">Ocean</a>
                        <a href="#">Senses</a>
                    </nav>
                </div>
                <div>
                    <div className={styles.order}>
                        <ul>
                            <li>
                                <button
                                    onClick={() => handleLayoutChange('vertical')}
                                    className={layout === 'vertical' ? styles.active : ''}
                                >
                                    Vertical
                                </button>
                            </li>
                            <li>
                                <button
                                    onClick={() => handleLayoutChange('horizontal')}
                                    className={layout === 'horizontal' ? styles.active : ''}
                                >
                                    Horizontal
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <CollectionsList layout={layout} />
        </div>
    );
};

export default CollectionsOptions;