import { useRef } from 'react';
import Slider from "react-slick";
import styles from 'styles/components/section/productdetail/gallery.module.scss';
import item1web from 'assets/img/section/productdetail/gallery/1_gr.webp';
import item2web from 'assets/img/section/productdetail/gallery/1_thumb.webp';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

const Gallery = () => {

    const mainSliderRef = useRef(null);
    const thumbSliderRef = useRef(null);

    const mainSettings = {
        asNavFor: thumbSliderRef.current,
        ref: (slider) => (mainSliderRef.current = slider),
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false, 
    };

    const thumbSettings = {
        asNavFor: mainSliderRef.current,
        ref: (slider) => (thumbSliderRef.current = slider),
        slidesToShow: 3,
        slidesToScroll: 1,
        focusOnSelect: true,
        centerMode: true, 
        arrows: true,
    };

    return (
        <div className={styles.gallery}>
            {/* Carrusel principal */}
            <Slider {...mainSettings} className={styles.mainSlider}>
                <div>
                    <img src={item1web} alt="Main Image 1" />
                </div>
                <div>
                    <img src={item1web} alt="Main Image 2" />
                </div>
                <div>
                    <img src={item1web} alt="Main Image 3" />
                </div>
            </Slider>

            {/* Carrusel de miniaturas */}
            <Slider {...thumbSettings} className={styles.thumbSlider}>
                <div>
                    <img src={item2web} alt="Thumbnail 1" />
                </div>
                <div>
                    <img src={item2web} alt="Thumbnail 2" />
                </div>
                <div>
                    <img src={item2web} alt="Thumbnail 3" />
                </div>
                <div>
                    <img src={item2web} alt="Thumbnail 4" />
                </div>
            </Slider>
        </div>
    );
}
 
export default Gallery;