import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";


//Layout components para vincular al router
import MasterLayout from "components/layouts/MasterLayout";
import ErrorLayout  from "components/layouts/ErrorLayout";

//Page components para vincular al router
import HomePage       from "pages/HomePage";
import CategoryPage   from "pages/CategoryPage";
import CollectionPage from "pages/CollectionPage";
import ContactPage    from "pages/ContactPage";
import ProductDetailPage    from "pages/ProductDetailPage";

const routes = createBrowserRouter([
    {
        //Rutas absolutas
        path: '/',
        element: <MasterLayout />,
        errorElement: <ErrorLayout />,
        children: [
            {
                index: true,
                element: <HomePage />,
            },
            {
                path: 'category',
                element: <CategoryPage />,
            },
            {
                path: 'collection',
                element: <CollectionPage />,
            },
            {
                path: 'product',
                element: <ProductDetailPage />,
            },
            {
                path: 'contact',
                element: <ContactPage/>
            },

        ]
    },
]);

const Router = () => {
    return <RouterProvider router={routes}/>;
}

export default Router;  