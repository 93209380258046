import styles from 'styles/components/common/footer/socialmedia.module.scss';
import facebook from '../../../assets/img/footer/facebook.svg';
import instagram from '../../../assets/img/footer/instagram.svg';
import tiktok from '../../../assets/img/footer/tiktok.svg';
import x from '../../../assets/img/footer/x.svg';
const SocialMedia = () => {
    return (
        <div className={styles.socialmedia}>
            <a href="" className={styles.facebook}><img src={facebook} alt="" /></a>
            <a href="" className={styles.instagram}><img src={instagram} alt="" /></a>
            <a href="" className={styles.tiktok}><img src={tiktok} alt="" /></a>
            <a href="" className={styles.x}><img src={x} alt="" /></a>
        </div>
    );
}

export default SocialMedia;