
export const variants = {

    list: {
        open: {
            opacity: 1,
            transition: {
                type: "spring",
                bounce: 0,
                duration: 0.7,
                delayChildren: 0.3,
                staggerChildren: 0.05
            }
        },
        closed: {
            opacity: 0,
            transition: {
                type: "spring",
                bounce: 0,
                duration: 0.3
            }
        }
    },
    arrow:{
        open: { rotate: 180 },
        closed: { rotate: 0 }
    },
    item: {
        open: {
            opacity: 1,
            y: 0,
            transition: { type: "spring", stiffness: 300, damping: 24 }
        },
        closed: { opacity: 0, y: 20, transition: { duration: 0.2 } }
    }

}