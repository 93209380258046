import styles from 'styles/components/common/footer/sitemap.module.scss';

const SiteMap = () => {
    return (
        <div className={styles.sitemap}>
            <div>
                <h3>Información</h3>
                <ul>
                    <li><a href="/">Términos y Condiciones</a></li>
                    <li><a href="/">Términos de Servicio</a></li>
                    <li><a href="/">Términos de Privacidad</a></li>
                </ul>
            </div>
            <div>
                <ul>
                    <li><a href="/">Cambios y Devoluciones</a></li>
                    <li><a href="/">Tabla de Tallas</a></li>
                    <li><a href="/">Envíos</a></li>
                    <li><a href="/">Cookies</a></li>
                </ul>
            </div>
        </div>
    );
}

export default SiteMap;