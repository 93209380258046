import { useEffect } from 'react';
import styles from 'styles/components/section/productdetail/product-container.module.scss';

import Gallery from 'components/pages/productdetail/Gallery';
import Details from 'components/pages/productdetail/Details';
const ProductContainer = () => {

    useEffect(() => {
        document.title = 'Art(e)onu - Nombre de producto';
      }, []);

    return (
        <div className={styles.productcontainer}>
            <Gallery />
            {/* <Details /> */}
        </div>
    );
};

export default ProductContainer;