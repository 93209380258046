import { useState } from 'react';
import styles from 'styles/components/section/category/collectionslist.module.scss';
import item1web from 'assets/img/section/category/collections/list/1_web.webp';
import item1mov from 'assets/img/section/category/collections/list/1_mov.webp';
import item2web from 'assets/img/section/category/collections/list/2_web.webp';
import item2mov from 'assets/img/section/category/collections/list/2_mov.webp';
import item3web from 'assets/img/section/category/collections/list/3_web.webp';
import item3mov from 'assets/img/section/category/collections/list/3_mov.webp';
import item4web from 'assets/img/section/category/collections/list/4_web.webp';
import item4mov from 'assets/img/section/category/collections/list/4_mov.webp';
const CollectionsList = ({ layout }) => {
    const items = [
        { id: 1, web: item1web, mov: item1mov },
        { id: 2, web: item2web, mov: item2mov },
        { id: 3, web: item3web, mov: item3mov },
        { id: 4, web: item4web, mov: item4mov }
    ];

    const itemsPerPage = 2; 
    const [currentPage, setCurrentPage] = useState(1);
    const totalPages = Math.ceil(items.length / itemsPerPage);

    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const currentItems = items.slice(startIndex, endIndex);

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };
    
    return (
        <div className={styles.collectionslist}>
            {Array.from({ length: 3 }, (_, i) => (
            <ul className={layout === 'vertical' ? styles.vertical : styles.horizontal}>
                {items.map((item, index) => (
                    <li key={item.id}>
                        <a href="/product">
                            <div className={styles.container}>
                                <picture>
                                    <source media="(max-width: 768px)" srcSet={item.mov} />
                                    <img src={item.web} alt={`Item ${index + 1}`} />
                                </picture>
                                <button className={styles.favorite}></button>
                                <button className={styles.share}></button>
                            </div>
                            <div className={styles.box}>
                                <div className={styles.line}>Línea | Categoría</div>
                                <div className={styles.name}>Nombre del artículo {index + 1}</div>
                                <div className={styles.price}>$000.00 MXN</div>
                                <div className={styles.description}>Sorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum tellus elit sed risus. Maecenas eget condimentum velit, sit amet feugiat lectus. </div>
                            </div>
                        </a>
                    </li>
                ))}
            </ul>
            ))} 
             <div className={styles.pagination}>
                <button onClick={handlePreviousPage} disabled={currentPage === 1} className={styles.arrowLeft}></button>

                {Array.from({ length: totalPages }, (_, i) => (
                    <button
                        key={i + 1}
                        onClick={() => handlePageChange(i + 1)}
                        className={`${styles.pageNumber} ${currentPage === i + 1 ? styles.active : ''}`}
                    >
                        {i + 1}
                    </button>
                ))}

                <button onClick={handleNextPage} disabled={currentPage === totalPages} className={styles.arrowRight}></button>
            </div>
        </div>
    );
}

export default CollectionsList;