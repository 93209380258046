import styles from 'styles/components/section/home/categories/gridcategory.module.scss';
import item1web from 'assets/img/section/home/categories/1web.webp';
import item1mov from 'assets/img/section/home/categories/1mov.webp';
import item2web from 'assets/img/section/home/categories/2web.webp';
import item2mov from 'assets/img/section/home/categories/2mov.webp';
import item3web from 'assets/img/section/home/categories/3web.webp';
import item3mov from 'assets/img/section/home/categories/3mov.webp';
import item4web from 'assets/img/section/home/categories/4web.webp';
import item4mov from 'assets/img/section/home/categories/4mov.webp';

const Categories = () => {
    return (
        <div className={styles.gridcategory}>
            <div className={styles.item}>
                <picture>
                    <source media="(max-width: 768px)" srcSet={item1mov} />
                    <img src={item1web} alt="" />
                </picture>
                <a href="/category" className={styles.linkbutton}><span>TOPS</span></a>
            </div>
            <div className={styles.item}>
                <picture>
                    <source media="(max-width: 768px)" srcSet={item2mov} />
                    <img src={item2web} alt="" />
                </picture>
                <a href="/category" className={styles.linkbutton}><span>BOTTOMS</span></a>
            </div>
            <div className={styles.item}>
                <picture>
                    <source media="(max-width: 768px)" srcSet={item3mov} />
                    <img src={item3web} alt="" />
                </picture>
                <a href="/category" className={styles.linkbutton}><span>JEWERLY</span></a>
            </div>
            <div className={styles.item}>
                <picture>
                    <source media="(max-width: 768px)" srcSet={item4mov} />
                    <img src={item4web} alt="" />
                </picture>
                <a href="/category" className={styles.linkbutton}><span>ACCESORIES</span></a>
            </div>
        </div>
    );
}

export default Categories;