//React hooks
import { useState, useEffect } from "react";


import { motion } from "framer-motion";
import Toggle from "./dropdown/Toggle";
import Item from "./dropdown/Item";
import styles from 'styles/components/common/form/dropdown.module.scss';
import { variants } from './dropdown/Variants';

export default function Dropdown({ placeholder, items, onSelectItem, defaultSelectedItem }) {
  const [isOpen, setIsOpen] = useState(false);
  const [chosen, setChosen] = useState(defaultSelectedItem ? defaultSelectedItem.value : placeholder);

  useEffect(() => {
    if (defaultSelectedItem) {
      setChosen(defaultSelectedItem.value);
      onSelectItem(defaultSelectedItem);
    }
  }, [defaultSelectedItem, onSelectItem]);

  function onToggle() {
    setIsOpen(!isOpen);
  }

  function onSelect(key, value) {
    onSelectItem({ key, value });
    setChosen(value);
    setIsOpen(false);
  }

  return (
    <motion.div 
      initial={false} 
      animate={isOpen ? "open" : "closed"} 
      className={styles.dropdown}
    >
      <Toggle 
        styles={styles.toggle} 
        onClick={onToggle} 
        variants={variants.arrow} 
        text={chosen}
      />
      <motion.ul variants={variants.list} style={{ zIndex: isOpen ? 1000 : 'auto' }}>
        { 
          items.map((item) => (
            <Item 
              key={item.key} 
              value={item.value} 
              variants={variants.item} 
              styles={styles.item} 
              onClick={() => onSelect(item.key, item.value)}
            />
          ))
        }
      </motion.ul>
    </motion.div>
  );
}