import styles from 'styles/components/common/breadcrumbs.module.scss';
const Breadcrumbs = ({ links }) => {
    return (
        <div className={styles.breadcrumbs}>
            <ul>
                {links.map((link, index) => (
                    <li key={index}>
                        <a href={link.href}>{link.text}</a>
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default Breadcrumbs;