import styles from 'styles/components/section/home/newarrivals/newarrivals.module.scss';
import item1web from 'assets/img/section/home/newarrivals/1web.webp';
import item1mov from 'assets/img/section/home/newarrivals/1mov.webp';
import item2web from 'assets/img/section/home/newarrivals/2web.webp';
import item2mov from 'assets/img/section/home/newarrivals/2mov.webp';
import item3web from 'assets/img/section/home/newarrivals/3web.webp';
import item3mov from 'assets/img/section/home/newarrivals/3mov.webp';
import item4web from 'assets/img/section/home/newarrivals/4web.webp';
import item4mov from 'assets/img/section/home/newarrivals/4mov.webp';
import item5web from 'assets/img/section/home/newarrivals/5web.webp';
import item5mov from 'assets/img/section/home/newarrivals/5mov.webp';

const NewArrivals = () => {
    return (
        <div className={styles.newarrivals}>
            
            <div className={styles.title}></div>

            <div className={styles.grid}>
                <div className={styles.item}>
                    <a href="">
                        <picture>
                            <source media="(max-width: 768px)" srcSet={item1mov} />
                            <img src={item1web} alt="" />
                        </picture>
                    </a>
                </div>
                <div className={styles.item}>
                    <a href="">
                        <picture>
                            <source media="(max-width: 768px)" srcSet={item2mov} />
                            <img src={item2web} alt="" />
                        </picture>
                    </a>
                </div>
                <div className={styles.item}>
                    <a href="">
                        <picture>
                            <source media="(max-width: 768px)" srcSet={item3mov} />
                            <img src={item3web} alt="" />
                        </picture>
                    </a>
                </div>
                <div className={styles.item}>
                    <a href="">
                        <picture>
                            <source media="(max-width: 768px)" srcSet={item4mov} />
                            <img src={item4web} alt="" />
                        </picture>
                    </a>
                </div>
                <div className={styles.item}>
                    <a href="">
                        <picture>
                            <source media="(max-width: 768px)" srcSet={item1mov} />
                            <img src={item1web} alt="" />
                        </picture>
                    </a>
                </div>
                <div className={styles.item}>
                    <a href="">
                        <picture>
                            <source media="(max-width: 768px)" srcSet={item5mov} />
                            <img src={item5web} alt="" />
                        </picture>
                    </a>
                </div>
                <div className={styles.item}>
                    <a href="">
                        <picture>
                            <source media="(max-width: 768px)" srcSet={item3mov} />
                            <img src={item3web} alt="" />
                        </picture>
                    </a>
                </div>
                <div className={styles.item}>
                    <a href="">
                        <picture>
                            <source media="(max-width: 768px)" srcSet={item4mov} />
                            <img src={item4web} alt="" />
                        </picture>
                    </a>
                </div>
            </div>
        </div>
    );
}

export default NewArrivals;

