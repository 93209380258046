import styles from 'styles/components/common/header/accountbutton.module.scss';

const  AccountButton = () => {

    return (
        <a href="" className={styles.accountbutton}>
            <span>Mi Cuenta</span>
        </a>
    );
}
 
export default AccountButton;