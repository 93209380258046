import styles from 'styles/components/section/category/list.module.scss';
import item1web from 'assets/img/section/category/list/1_web.webp';
import item1mov from 'assets/img/section/category/list/1_mov.webp';
import item2web from 'assets/img/section/category/list/2_web.webp';
import item2mov from 'assets/img/section/category/list/2_mov.webp';
import item3web from 'assets/img/section/category/list/3_web.webp';
import item3mov from 'assets/img/section/category/list/3_mov.webp';
import item4web from 'assets/img/section/category/list/4_web.webp';
import item4mov from 'assets/img/section/category/list/4_mov.webp';
const CategoriesList = () => {
    return (
        <div className={styles.list}>
            <ul>
                <li>
                    <picture>
                        <source media="(max-width: 768px )" srcset={item1mov} />
                        <img src={item1web} alt="" />
                    </picture>
                    <a href="/collection" className={styles.linkbuttontransparent}><span>Tank Tops</span></a>
                </li>
                <li>
                    <picture>
                        <source media="(max-width: 768px )" srcset={item2mov} />
                        <img src={item2web} alt="" />
                    </picture>
                    <a href="" className={styles.linkbuttontransparent}><span>T-Shirts</span></a>
                </li>
                <li>
                    <picture>
                        <source media="(max-width: 768px )" srcset={item3mov} />
                        <img src={item3web} alt="" />
                    </picture>
                    <a href="" className={styles.linkbuttontransparent}><span>Hoodies</span></a>
                </li>
                <li>
                    <picture>
                        <source media="(max-width: 768px )" srcset={item4mov} />
                        <img src={item4web} alt="" />
                    </picture>
                    <a href="" className={styles.linkbuttontransparent}><span>Jackets</span></a>
                </li>
            </ul>
        </div>
    );
}

export default CategoriesList;