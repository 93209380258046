import { useEffect } from 'react';

//Componentes personalizados 
import styles from 'styles/components/common/container.module.scss';

import Breadcrumbs from 'components/common/Breadcrumbs';
import Product from 'components/pages/productdetail/Product';

const CategoryPage = () => {

    const breadcrumbLinks = [
        { href: '/', text: 'Home' },
        { href: '/tops', text: 'Tops' },
        { href: '/t-shirts', text: 'T-Shirts' },
        { href: '/', text: 'Nombre del producto' }
    ];

    useEffect(() => {
        document.title = 'Art(e)onu - Detalle de producto';
      }, []);

    return (
        <div className={styles.container}>
            <Breadcrumbs links={breadcrumbLinks} />

            <Product />
        </div>
    );
}
 
export default CategoryPage;