import styles from 'styles/components/common/footer.module.scss';

import Logo from 'components/common/header/Logo';
import SiteMap from 'components/common/footer/SiteMap';
import SocialMedia from 'components/common/footer/SocialMedia';
import Legal from 'components/common/footer/Legal';

const Footer = () => {
    return (
    <footer className={styles.footer}>
        <div className={styles.grid}>

            <div className={styles.logo}>
                <Logo />
            </div>

            <SiteMap />

            <SocialMedia />
        </div>

        <Legal />

    </footer>
    );
}
 
export default Footer;