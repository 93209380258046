import { useEffect } from 'react';

//Componentes personalizados 
import styles from 'styles/components/common/container.module.scss';

import Breadcrumbs from 'components/common/Breadcrumbs';
import Titles from 'components/common/Titles';
import CategoriesList from 'components/pages/category/CategoriesList';

const CategoryPage = () => {

    const title = 'TOPS';

    const breadcrumbLinks = [
        { href: '/', text: 'Home' },
        { href: '/tops', text: 'Tops' }
    ];


    useEffect(() => {
        document.title = 'Art(e)onu - Categorías';
      }, []);

    return (
        <div className={styles.container}>
            <Breadcrumbs links={breadcrumbLinks} />

            <Titles title={title} />

            <CategoriesList />
        </div>
    );
}
 
export default CategoryPage;