import { useEffect } from 'react';

//Componentes personalizados 
import FeaturedBanner from 'components/pages/home/FeaturedBanner';
import Categories     from 'components/pages/home/Categories';
import YourSelf     from 'components/pages/home/YourSelf';
import NewArrivals     from 'components/pages/home/NewArrivals';
import AboutFans     from 'components/pages/home/AboutFans';

const HomePage = () => {

    useEffect(() => {
        document.title = 'Art(e)onu';
      }, []);

    return (
        <>
            <FeaturedBanner />

            <Categories />

            <YourSelf />

            <NewArrivals />

            <AboutFans />
        </>
    );
}
 
export default HomePage;