import React, { useState, useEffect, useRef } from 'react';
import Slider from 'react-slick';
import styles from 'styles/components/section/home/yourself/yourself.module.scss';
import item1web from 'assets/img/section/home/yourself/1web.webp';
import item2web from 'assets/img/section/home/yourself/2web.webp';
import item3web from 'assets/img/section/home/yourself/3web.webp';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import "styles/components/section/home/yourself/slick.css";

const YourSelf = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 1023);
    const sliderRef = useRef(null);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 1023);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const settings = {
        autoplay: true,
        speed: 1000,
        arrows: false,
        dots: false,
        focusOnSelect: true,
        infinite: true,
        centerMode: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        centerPadding: '0',
        swipe: true,
        customPaging: () => <div className={styles.customPaging} />,
        beforeChange: (current, next) => {
            const slider = sliderRef.current;
            const allSlides = document.querySelectorAll('.slick-slide');

            allSlides.forEach((slide) => {
                slide.classList.remove('slick-sprev', 'slick-snext');
            });

            // Identificar el slide anterior y el siguiente
            const prevIndex = next === 0 ? allSlides.length - 1 : next - 1;
            const nextIndex = next === allSlides.length - 1 ? 0 : next + 1;

            if (allSlides[prevIndex]) allSlides[prevIndex].classList.add('slick-sprev');
            if (allSlides[nextIndex]) allSlides[nextIndex].classList.add('slick-snext');
        },
        afterChange: (current) => {
            const allSlides = document.querySelectorAll('.slick-slide');

            allSlides.forEach((slide) => {
                slide.classList.remove('slick-sprev', 'slick-snext');
            });

            // Identificar el slide anterior y el siguiente
            const prevIndex = current === 0 ? allSlides.length - 1 : current - 1;
            const nextIndex = current === allSlides.length - 1 ? 0 : current + 1;

            if (allSlides[prevIndex]) allSlides[prevIndex].classList.add('slick-sprev');
            if (allSlides[nextIndex]) allSlides[nextIndex].classList.add('slick-snext');
        },
    };

    return (
        <div className={styles.yourself}>
            <div className={styles.title}></div>
            {isMobile ? (
                <Slider {...settings} className={`${styles.slidegrid} slick-yourself`}>
                    <div>
                        <div className={styles.item}>
                            <picture>
                                <img src={item1web} alt="" />
                            </picture>
                            <div className={styles.boxtext}>
                                <i></i>
                                <div>
                                    @Ryan_9875 I really loved my new outfit, thank you @ArtOnU for making the most beautiful trip possible
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                    <div className={styles.item}>
                        <picture>
                            <img src={item2web} alt="" />
                        </picture>
                        <div className={styles.boxtext}>
                            <i></i>
                            <div>
                                @Ryan_9875 I really loved my new outfit, thank you @ArtOnU for making the most beautiful trip possible
                            </div>
                        </div>
                    </div>
                    </div>
                    <div>
                    <div className={styles.item}>
                        <picture>
                            <img src={item1web} alt="" />
                        </picture>
                        <div className={styles.boxtext}>
                            <i></i>
                            <div>
                                @Ryan_9875 I really loved my new outfit, thank you @ArtOnU for making the most beautiful trip possible
                            </div>
                        </div>
                    </div>
                    </div>
                    <div>
                    <div className={styles.item}>
                        <picture>
                            <img src={item3web} alt="" />
                        </picture>
                        <div className={styles.boxtext}>
                            <i></i>
                            <div>
                                @Ryan_9875 I really loved my new outfit, thank you @ArtOnU for making the most beautiful trip possible
                            </div>
                        </div>
                    </div>
                    </div>
                    <div>
                    <div className={styles.item}>
                        <picture>
                            <img src={item2web} alt="" />
                        </picture>
                        <div className={styles.boxtext}>
                            <i></i>
                            <div>
                                @Ryan_9875 I really loved my new outfit, thank you @ArtOnU for making the most beautiful trip possible
                            </div>
                        </div>
                    </div>
                    </div>
                    <div>
                    <div className={styles.item}>
                        <picture>
                            <img src={item3web} alt="" />
                        </picture>
                        <div className={styles.boxtext}>
                            <i></i>
                            <div>
                                @Ryan_9875 I really loved my new outfit, thank you @ArtOnU for making the most beautiful trip possible
                            </div>
                        </div>
                    </div>
                    </div>
                </Slider>
            ) : (
                <div className={styles.slidegrid}>
                    <div>
                    <div className={styles.item}>
                        <picture>
                            <img src={item1web} alt="" />
                        </picture>
                        <div className={styles.boxtext}>
                            <i></i>
                            <div>
                                @Ryan_9875 I really loved my new outfit, thank you @ArtOnU for making the most beautiful trip possible
                            </div>
                        </div>
                    </div>
                    </div>
                    <div>
                    <div className={styles.item}>
                        <picture>
                            <img src={item2web} alt="" />
                        </picture>
                        <div className={styles.boxtext}>
                            <i></i>
                            <div>
                                @Ryan_9875 I really loved my new outfit, thank you @ArtOnU for making the most beautiful trip possible
                            </div>
                        </div>
                    </div>
                    </div>
                    <div>
                    <div className={styles.item}>
                        <picture>
                            <img src={item1web} alt="" />
                        </picture>
                        <div className={styles.boxtext}>
                            <i></i>
                            <div>
                                @Ryan_9875 I really loved my new outfit, thank you @ArtOnU for making the most beautiful trip possible
                            </div>
                        </div>
                    </div>
                    </div>
                    <div>
                    <div className={styles.item}>
                        <picture>
                            <img src={item3web} alt="" />
                        </picture>
                        <div className={styles.boxtext}>
                            <i></i>
                            <div>
                                @Ryan_9875 I really loved my new outfit, thank you @ArtOnU for making the most beautiful trip possible
                            </div>
                        </div>
                    </div>
                    </div>
                    <div>
                    <div className={styles.item}>
                        <picture>
                            <img src={item2web} alt="" />
                        </picture>
                        <div className={styles.boxtext}>
                            <i></i>
                            <div>
                                @Ryan_9875 I really loved my new outfit, thank you @ArtOnU for making the most beautiful trip possible
                            </div>
                        </div>
                    </div>
                    </div>
                    <div>
                    <div className={styles.item}>
                        <picture>
                            <img src={item3web} alt="" />
                        </picture>
                        <div className={styles.boxtext}>
                            <i></i>
                            <div>
                                @Ryan_9875 I really loved my new outfit, thank you @ArtOnU for making the most beautiful trip possible
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default YourSelf;