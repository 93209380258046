//Componentes personalizados
import AccountButton   from 'components/common/header/AccountButton';
import CartButton      from 'components/common/header/CartButton';
import FavoritesButton from 'components/common/header/FavoritesButton';
import SidemenuButton  from 'components/common/header/SidemenuButton';
import Logo            from 'components/common/header/Logo';
import Navigation      from 'components/common/header/Navigation';
import Search          from 'components/common/header/Search';
import Settings        from 'components/common/header/Settings';

//Módulo de estilos
import styles from 'styles/components/common/header.module.scss';

const Header = () => {

    return (

        <header className={styles.header}>
            <section>
                <ul>

                    <li>

                        <Logo />

                    </li>

                    <li>

                        <Search />

                    </li>

                    <li>

                        <AccountButton />

                        <FavoritesButton />

                        <CartButton />

                        <SidemenuButton />

                    </li>

                </ul>

                <Settings />

            </section>

            <Navigation />

        </header>

    );
}

export default Header;