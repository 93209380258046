//Librería de animación
import { motion } from "framer-motion";

const Item = ({ variants, key, value, styles, onClick }) => {

    return (
        <motion.li variants={variants} key={key}>
            <button className={styles} onClick={() => onClick(key, value)}>
                {value}
            </button>
        </motion.li>
    );

}

export default Item;