import styles from 'styles/components/common/header/search.module.scss';

const  Search = () => {

    return (
        <form action="">
            <div className={styles.search}>
                
                <input type="text" className={styles.field} name="" id="" placeholder="Buscar un producto" />
                <input type="submit" className={styles.icosearch} value="" />
                
            </div>
        </form>
    );
}
 
export default Search;