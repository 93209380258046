import { Fragment } from 'react';
import { Outlet } from 'react-router-dom';

import Header   from 'components/common/Header';
import Content  from 'components/common/Content';
import Footer   from 'components/common/Footer';
import Sidemenu from 'components/common/Sidemenu';

const MasterLayout = () => {

    return (
        <Fragment>
            
            <Header/>

            <Content>
                <Outlet />
            </Content>

            <Footer/>

            <Sidemenu/>

        </Fragment>
    );
}

export default MasterLayout;