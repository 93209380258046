import { useEffect } from 'react';

//Componentes personalizados 
import styles from 'styles/components/common/container.module.scss';

import Breadcrumbs from 'components/common/Breadcrumbs';
import Titles from 'components/common/Titles';
import CollectionsOptions from 'components/pages/category/CollectionsOptions';

const CategoryPage = () => {

    const title = 'T-Shirts';

    const breadcrumbLinks = [
        { href: '/', text: 'Home' },
        { href: '/tops', text: 'Tops' },
        { href: '/', text: 'T-Shirts' }
    ];

    useEffect(() => {
        document.title = 'Art(e)onu - Categorías';
      }, []);

    return (
        <div className={styles.container}>
            <Breadcrumbs links={breadcrumbLinks} />

            <Titles title={title} />

            <CollectionsOptions />

        </div>
    );
}
 
export default CategoryPage;