import styles from 'styles/components/section/home/featured/bannerfeatured.module.scss';
import featuredweb from '../../../assets/img/section/home/featured/web.webp';
import featuredmov from '../../../assets/img/section/home/featured/mov.webp';
const FeaturedBanner = () => {
    return (
        <div className={styles.bannerfeatured}>
            <picture>
                <source media="(max-width: 768px)" srcSet={featuredmov} />
                <img src={featuredweb} alt="" />
            </picture>
            <a href="" className={styles.linkbutton}><span>VER COLECCIÓN</span></a>
        </div>
    );
}

export default FeaturedBanner;