import styles from 'styles/components/common/header/navigation.module.scss';

const  Navigation = () => {

    return (
        <nav className={styles.navigation}>
            <a href="#">Bañadores</a>
            <a href="#">Fullprint</a>
            <a href="#">Sport</a>
            <a href="#">Accesorios</a>
            <a href="#">Vestidos</a>
            <a href="#">Categoría 1</a>
            <a href="#">Categoría 1</a>
        </nav>
    );
}
 
export default Navigation;