import Dropdown from "components/common/form/Dropdown";
import styles from 'styles/components/common/header/settings.module.scss';

const Settings = () => {
  const items = [
    { key: 1, value: 'Español' },
    { key: 2, value: 'Inglés' },
  ];

  const currency = [
    { key: 1, value: '$MXN' },
    { key: 2, value: '$USD' },
  ];

  function selectCurrency(value) {
    console.log(value);
  }

  function selectLanguage(value) {
    console.log(value);
  }

  return (
    <div className={styles.settings}>
      <div>
        <Dropdown
          className={styles.dropdownhead}
          placeholder="Seleccionar idioma"
          items={items}
          onSelectItem={selectLanguage}
          defaultSelectedItem={items[0]} 
        />
      </div>
      <div>
        <Dropdown
          placeholder="Seleccionar moneda"
          items={currency}
          onSelectItem={selectCurrency}
          defaultSelectedItem={currency[0]} 
        />
      </div>
    </div>
  );
};

export default Settings;