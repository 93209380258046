//Librería de animación
import { motion} from "framer-motion";

import Arrow from "./Arrow";

const Toggle = ({text, styles, onClick, variants}) => {

    return (
        <motion.button className={styles} onClick={onClick}>
        
            {text}

            <motion.div variants={variants} transition={{ duration: 0.2 }} style={{ originY: 0.55 }}>
        
                <Arrow/>

            </motion.div>

      </motion.button>
    );

}
 
export default Toggle;