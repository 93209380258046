import styles from 'styles/components/section/home/aboutfans/gridaboutfans.module.scss';
const AboutFans = () => {
    return (
        <div className={styles.gridaboutfans}>
            
            <div className={styles.itemwhite}>
                <div className={styles.title}></div>
                <p>A veces atravesar una crisis es un buen motivo para empezar algo positivo. Es así como la historia de Arte On U comenzó de la mano de su fundador y Director Artístico, Miguel Pérez Lem. En 2020....</p>
                <a href="" className={styles.linkbuttonwhite}><span>LEER MÁS</span></a>
            </div>
            <div  className={styles.itemgray}>
                <div className={styles.title}></div>
                <div className={styles.back}></div>
                <ul>
                    <li>10% off your first online order.</li>
                    <li>Accumulate points and earn benefits</li>
                    <li>Shopping assistance with the designer</li>
                    <li>And more</li>
                </ul>
                <a href="" className={styles.linkbuttonwhite}><span>Sign Up</span></a>
            </div>
            
        </div>
    );
}

export default AboutFans;

